import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getHeaderTitles, getNextQuizLink, getNextTitle, getPreviousQuizLink } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 2
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getPreviousQuizLink(myPage, mySubpage, questionNumber)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FIVE"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={
          "There is a positive relationship between athletics and _________________?"
        }
        answers={[
          "Higher anxiety",
          "Higher weight gain",
          "Higher body esteem",
          "Higher blood pressure",
        ]}
        correctAnswerNumber={2}
        answerDetail={
          "There is a positive relationship between athletics and body esteem among girls. Girls across all grade levels who played three or more sports per year have higher scores on body esteem."
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 5, 6)
